import React from 'react';
import { FaPlay, FaCheck, FaArrowLeft } from 'react-icons/fa'; // Font Awesome icons for deletion and play


const MusicTable = ({ selectedExercise, selectedMusicDetails, handleSort, accessToken, handleSelectMusicExercise, playSong }) => {
  return (
    <div className="music-table">
      <h2>Opciones de Música para {selectedExercise.musica_ejercicio.ejercicio.name}</h2>
      {selectedMusicDetails.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th onClick={() => handleSort('name')}>Canción</th>
              <th onClick={() => handleSort('interprete')}>Artista</th>
              <th onClick={() => handleSort('ult_fecha_mus')}>Último Uso</th>
              <th onClick={() => handleSort('ult_fecha_mus_ej')}>Último Uso Ejercicio</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {selectedMusicDetails.map((detail, index) => (
              <tr 
                key={index} 
                className={selectedExercise && selectedExercise.musica_ejercicio.musica && detail.musica.id === selectedExercise.musica_ejercicio.musica.id ? 'highlight' : ''} 
              >
                <td>{detail.musica.name}</td>
                <td>{detail.musica.interprete.name}</td>
                <td>{detail.musica.ult_fecha_mus}</td>
                <td>{detail.musica.ult_fecha_mus_ej}</td>
                <td>
                  <FaPlay 
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent row click from triggering
                      playSong(detail.musica.name, detail.musica.interprete.name, detail.musica.spotify_id, accessToken);
                    }} 
                    style={{ cursor: 'pointer', color: 'green' }}
                    title="Play"
                  />
                  <FaCheck 
                    onClick={() => handleSelectMusicExercise(detail)} 
                    style={{ cursor: 'pointer', color: 'green' }}
                    title="Select Music"
                  />
                  {/* <button
                    onClick={() => handleSelectMusicExercise(detail)}
                    style={{ marginLeft: '10px' }}
                  >
                    Select
                  </button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Select an exercise to view details.</p>
      )}
    </div>
  );
};

export default MusicTable;
