import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { playSong, createSpotifyPlaylist } from '../../utils/spotify';
import ExerciseSelectionModal from './ExerciseSelectionModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import ConfirmationModal from './ConfirmationModal';
import SpotifyAuthCheck from './SpotifyAuthCheck';
import SessionDataFetcher from './SessionDataFetcher';
import ExerciseTable from './ExerciseTable';
import MusicTabs from './MusicTabs';
import './SessionBuilder.css';
import { useAuth } from '../../AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaSave, FaSpotify, FaArrowUp, FaArrowDown, FaPlus, FaCopy } from 'react-icons/fa';


const SessionBuilder = ({ spotifyToken, setSpotifyToken }) => {
  const { authFetch } = useAuth();
  const { sessionId } = useParams();
  const [session, setSession] = useState(null);
  const [exercises, setExercises] = useState([]);
  const [preloadedExercises, setPreloadedExercises] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [musicDetails, setMusicDetails] = useState({});
  const [selectedMusicDetails, setSelectedMusicDetails] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [isSpotifyAuthenticated, setIsSpotifyAuthenticated] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [exerciseToDelete, setExerciseToDelete] = useState(null);
  const [deletedExerciseIds, setDeletedExerciseIds] = useState([]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);


  // Function to handle the confirmation modal open
  const openConfirmationModal = () => {
    setIsConfirmationModalOpen(true);
  };

  // Function to confirm duplication
  const handleConfirmDuplicate = async () => {
    try {
      const newSession = await authFetch(`/sesiones/api/sesion/${sessionId}/duplicate/`, { method: 'POST' });
      
      if (newSession.id) {
        window.location.href = `/sesion/${newSession.id}`;
      } else {
        alert('Failed to duplicate session');
      }
    } catch (error) {
      console.error('Error duplicating session:', error);
      alert('An error occurred while duplicating the session');
    }
    setIsConfirmationModalOpen(false);  // Close the confirmation modal
  };

  // Function to cancel duplication
  const handleCancelDuplicate = () => {
    setIsConfirmationModalOpen(false);  // Close the confirmation modal
  };

  const handleCreatePlaylist = async () => {
    if (!isSpotifyAuthenticated) {
      alert('Please authenticate to create a playlist.');
      return;
    }

    try {
      // Create the playlist on Spotify
      if (exercises.length > 0) {
        await createSpotifyPlaylist(session.name, exercises, spotifyToken);
        alert('Playlist created successfully!');
      } else {
        alert('No valid URIs found to create a playlist.');
      }
    } catch (error) {
      console.error('Error creating playlist:', error);
    }
  };

  const moveExerciseUp = () => {
    if (selectedExercise) {
      const currentIndex = exercises.findIndex(
        (exercise) => exercise.musica_ejercicio.ejercicio.id === selectedExercise.musica_ejercicio.ejercicio.id
      );
      if (currentIndex > 0) {
        const newExercises = [...exercises];
        [newExercises[currentIndex - 1], newExercises[currentIndex]] = [newExercises[currentIndex], newExercises[currentIndex - 1]];
        setExercises(newExercises);
      }
    }
  };

  const moveExerciseDown = () => {
    if (selectedExercise) {
      const currentIndex = exercises.findIndex(
        (exercise) => exercise.musica_ejercicio.ejercicio.id === selectedExercise.musica_ejercicio.ejercicio.id
      );
      if (currentIndex < exercises.length - 1) {
        const newExercises = [...exercises];
        [newExercises[currentIndex + 1], newExercises[currentIndex]] = [newExercises[currentIndex], newExercises[currentIndex + 1]];
        setExercises(newExercises);
      }
    }
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  // Function to update the selected music for the current exercise
  const handleSelectMusicExercise = (musicExercise) => {
    if (selectedExercise) {
      // Find the index of the selected exercise in the exercises array
      setExercises((prevExercises) =>
        prevExercises.map((ex) =>
          ex.musica_ejercicio.ejercicio.id === selectedExercise.musica_ejercicio.ejercicio.id
            ? {
              ...ex,
              musica_ejercicio: musicExercise,
            }
            : ex
        )
      );
    }
  };

  // Delete exercise function
  const handleDeleteExercise = (exercise) => {
    setIsDeleteModalOpen(true);
    setExerciseToDelete(exercise);
  };

  const confirmDeleteExercise = () => {
    if (exerciseToDelete && exerciseToDelete.id) {
      setDeletedExerciseIds((prev) => [...prev, exerciseToDelete.id]);
    }
    setExercises((prevExercises) =>
      prevExercises.filter((exercise) => exercise.musica_ejercicio.ejercicio.id !== exerciseToDelete.musica_ejercicio.ejercicio.id)
    );
    setIsDeleteModalOpen(false);
    setExerciseToDelete(null);
    setSelectedExercise(null);
  };

  const cancelDeleteExercise = () => {
    setIsDeleteModalOpen(false);
    setExerciseToDelete(null);
  };

  const handleAddExercise = async (exercise) => {
    const newExercise = {
      musica_ejercicio: {
        ejercicio: exercise,
        musica: null,
      },
    };

    setExercises((prevExercises) => {
      if (selectedExercise) {
        const selectedIndex = prevExercises.findIndex(
          (ex) => ex.musica_ejercicio.ejercicio.id === selectedExercise.musica_ejercicio.ejercicio.id
        );

        // Insert the new exercise after the selected one
        const updatedExercises = [...prevExercises];
        updatedExercises.splice(selectedIndex + 1, 0, newExercise);
        return updatedExercises;
      } else {
        // Add to the end if no exercise is selected
        return [...prevExercises, newExercise];
      }
    });
    setIsModalOpen(false);

    // Fetch music details for the selected exercise
    try {
      const musicData = await authFetch(`/sesiones/api/musicas_ejercicio/${exercise.id}/${sessionId}/`);
      setMusicDetails((prevMusicDetails) => ({
        ...prevMusicDetails,
        [exercise.id]: musicData,
      }));
    } catch (error) {
      console.error('Error fetching music details:', error);
    }
  };

  const handleExerciseClick = (exercise) => {
    setSelectedExercise(exercise);
    const musicForSelectedExercise = musicDetails[exercise.musica_ejercicio.ejercicio.id] || [];
    setSelectedMusicDetails(musicForSelectedExercise);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sortedMusic = [...selectedMusicDetails].sort((a, b) => {
      if (key === 'name') {
        return direction === 'ascending'
          ? a.musica[key].localeCompare(b.musica[key])
          : b.musica[key].localeCompare(a.musica[key]);
      } else if (key === 'interprete') {
        return direction === 'ascending'
          ? a.musica.interprete.name.localeCompare(b.musica.interprete.name)
          : b.musica.interprete.name.localeCompare(a.musica.interprete.name);
      } else {
        return direction === 'ascending'
          ? new Date(a[key]) - new Date(b[key])
          : new Date(b[key]) - new Date(a[key]);
      }
    });

    setSelectedMusicDetails(sortedMusic);
    setSortConfig({ key, direction });
  };

  const saveSession = () => {
    const updatedExercises = exercises.map((exercise) => {
      const { musica_ejercicio, id } = exercise;

      // If musica_ejercicio has an id, send only the id, and include the exercise id
      if (musica_ejercicio.id) {
        return {
          id,
          musica_ejercicio: { id: musica_ejercicio.id }
        };  
      } else {
        return {
          musica_ejercicio: {
            musica: { id: musica_ejercicio.musica?.id },
            ejercicio: { id: musica_ejercicio.ejercicio.id },
          },
          id
        };
      }
    });

    const updatedSession = {
      ...session,
      ejercicios: updatedExercises,
      deleted_exercises: deletedExerciseIds
    };

    authFetch(`/sesiones/api/sesion/${sessionId}/`, {
      method: 'PUT',
      body: updatedSession,
    })
      .then((data) => {
        console.log('Session updated successfully:', data);
        // Clear deletedExerciseIds after saving
        setDeletedExerciseIds([]);
        setLoading(true);
      })
      .catch((error) => console.error('Error updating session:', error));
  };

  useEffect(() => {
    // Preload exercises when component mounts
    const fetchPreloadedExercises = async () => {
      try {
        const data = await authFetch('/sesiones/api/exercises/');
        setPreloadedExercises(data);
      } catch (error) {
        console.error('Error fetching exercises:', error);
      }
    };

    fetchPreloadedExercises();
  }, []);

  if (loading) {
    return (
      <div>
        <div className="loading-spinner">Loading...</div>
        <SessionDataFetcher sessionId={sessionId} setSession={setSession} setExercises={setExercises} setMusicDetails={setMusicDetails} setLoading={setLoading} />
      </div>
    );
  }

  return (
    <div className="session-builder">
      <h2>{session?.name}</h2>
      <SpotifyAuthCheck accessToken={spotifyToken} setAccessToken={setSpotifyToken} setIsAuthenticated={setIsSpotifyAuthenticated} />
      {/* Save and Playlist Buttons */}

      <div className="action-buttons">
        <FaSave
          onClick={saveSession}
          style={{ cursor: 'pointer', color: 'green' }}
          title="Save Session"
          size={25}
        />
        <FaSpotify
          onClick={handleCreatePlaylist}
          style={{ cursor: 'pointer', color: 'green' }}
          title="Create Spotify Playlist"
          size={25}
        />
        <FaCopy
          // icon={['fas', 'copy']} // Use the 'copy' icon from FontAwesome
          onClick={openConfirmationModal}
          style={{ cursor: 'pointer', color: 'green' }}
          title="Duplicate Session"
          size={25}
        />
      </div>
      <div className="table-container">
        <div className="exercise-section">

          <ExerciseTable
            exercises={exercises}
            onExerciseClick={handleExerciseClick}
            selectedExercise={selectedExercise}
            onDeleteExercise={handleDeleteExercise}
            playSong={playSong}
            accessToken={spotifyToken}
          />
          <div className="move-buttons">
            {selectedExercise && (
              <>
                {/* Move Up Button */}
                <FaArrowUp
                  onClick={moveExerciseUp}
                  style={{
                    cursor: exercises[0] === selectedExercise ? 'not-allowed' : 'pointer',
                    opacity: exercises[0] === selectedExercise ? 0.5 : 1,
                    color: 'green',
                    fontSize: '24px', // Adjust size
                  }}
                  title="Move Up"
                />

                {/* Move Down Button */}
                <FaArrowDown
                  onClick={moveExerciseDown}
                  style={{
                    cursor: exercises[exercises.length - 1] === selectedExercise ? 'not-allowed' : 'pointer',
                    opacity: exercises[exercises.length - 1] === selectedExercise ? 0.5 : 1,
                    color: 'green',
                    fontSize: '24px', // Adjust size
                  }}
                  title="Move Down"
                />
              </>
            )}
            <FaPlus
              onClick={handleOpenModal}
              style={{
                cursor: 'pointer',
                color: 'green',
                fontSize: '24px', // Adjust size
              }}
              title="Add Exercise"
            />
          </div>
        </div>


        {selectedExercise && (
          <div className="music-tabs">
            <MusicTabs
              selectedExercise={selectedExercise}
              selectedMusicDetails={selectedMusicDetails}
              handleSort={handleSort}
              accessToken={spotifyToken}
              handleSelectMusicExercise={handleSelectMusicExercise}
              playSong={playSong}
              sessionId={sessionId}
            />
          </div>
        )}

      </div>

      <ExerciseSelectionModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        availableExercises={preloadedExercises}
        onSelectExercise={handleAddExercise}
        sessionExercises={exercises}
      />

      {/* Render the delete confirmation modal */}
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onConfirm={confirmDeleteExercise}
        onCancel={cancelDeleteExercise}
      />

      {/* Confirmation Modal */}
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onConfirm={handleConfirmDuplicate}
        onCancel={handleCancelDuplicate}
      />
    </div>
  );
};

export default SessionBuilder;
