import React, { useState, useEffect } from 'react';
import MusicTable from './MusicTable';
import './MusicTabs.css'; // Create a CSS file for tab styles
import { useAuth } from '../../AuthContext';
import { debounce } from 'lodash'; // Install lodash for debouncing
import { extractSpotifyId, fetchSpotifyTrackInfo } from '../../utils/spotify'; // Import the functions from spotify.js

const MusicTabs = ({ selectedExercise, selectedMusicDetails, handleSort, accessToken, handleSelectMusicExercise, playSong, sessionId }) => {
  const { authFetch } = useAuth();
  const [activeTab, setActiveTab] = useState('Tab1');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredMusicDataForTab2, setFilteredMusicDataForTab2] = useState([]);
  const [spotifyLink, setSpotifyLink] = useState('');
  const [spotifyTrackInfo, setSpotifyTrackInfo] = useState(null);
  const [trackSearchResults, setTrackSearchResults] = useState([]);


  const musicDataForTab1 = selectedMusicDetails; // Data for the first tab

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Debounced search function to reduce API calls while typing
  const fetchMusicData = debounce(async (query) => {
    if (query) {
      try {
        const data = await authFetch(`/sesiones/api/musicas/search/${selectedExercise.id}/${sessionId}/`, {
          params: { search: query },
        });
        // Format the response data to include the selected exercise
        const formattedMusicData = data.map((music) => ({
          musica: music,
          ejercicio: selectedExercise.musica_ejercicio.ejercicio,
        }));

        setFilteredMusicDataForTab2(formattedMusicData);
      } catch (error) {
        console.error('Error fetching music data:', error);
      }
    } else {
      setFilteredMusicDataForTab2([]); // Clear results if search is empty
    }
  }, 300);

  useEffect(() => {
    fetchMusicData(searchQuery);
    // Cleanup debounce on unmount
    return () => fetchMusicData.cancel();
  }, [searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSpotifyLinkSubmit = async () => {
    const spotifyId = extractSpotifyId(spotifyLink);
    if (spotifyId) {
      const trackInfo = await fetchSpotifyTrackInfo(spotifyId, accessToken);
      if (trackInfo) {
        setSpotifyTrackInfo(trackInfo);

        // Call the new API to search for the track using spotify_id first
        try {
          const searchResults = await authFetch(`/sesiones/api/musicas/track-artist-search/`, {
            method: 'GET', // Use GET method for search
            params: {
              spotify_id: spotifyId,
              track_name: trackInfo.name,
              artist_name: trackInfo.artist,
            },
          });

          setTrackSearchResults(searchResults); // Set the results to state
        } catch (error) {
          console.error('Error searching for track in the database:', error);
        }
      } else {
        alert('Could not fetch track info');
      }
    } else {
      alert('Invalid Spotify link');
    }
  };

  return (
    <div className="music-tabs">
      <div className="tab-titles">
        <button
          className={activeTab === 'Tab 1' ? 'active' : ''}
          onClick={() => handleTabChange('Tab1')}
        >
          Músicas Ejercicio
        </button>
        <button
          className={activeTab === 'Tab 2' ? 'active' : ''}
          onClick={() => handleTabChange('Tab2')}
        >
          Buscar
        </button>
        <button
          className={activeTab === 'Spotify Link' ? 'active' : ''}
          onClick={() => handleTabChange('Spotify Link')}
        >
          Spotify Link
        </button>
      </div>

      <div className="tab-content">
        {activeTab === 'Tab1' && (
          <MusicTable
            selectedExercise={selectedExercise}
            selectedMusicDetails={musicDataForTab1}
            handleSort={handleSort}
            accessToken={accessToken}
            handleSelectMusicExercise={handleSelectMusicExercise}
            playSong={playSong}
          />
        )}
        {activeTab === 'Tab2' && (
          <div>
            <input
              type="text"
              placeholder="Search musicas..."
              value={searchQuery}
              onChange={handleSearchChange}
              className="search-box"
            />
            <MusicTable
              selectedExercise={selectedExercise}
              selectedMusicDetails={filteredMusicDataForTab2}
              handleSort={handleSort}
              accessToken={accessToken}
              handleSelectMusicExercise={handleSelectMusicExercise}
              playSong={playSong}
            />
          </div>
        )}

        {activeTab === 'Spotify Link' && (
          <div>
            <div className="spotify-row">
              <input
                type="text"
                value={spotifyLink}
                onChange={(e) => setSpotifyLink(e.target.value)}
                placeholder="Paste Spotify track link"
                className="spotify-link-input"
              />
              <button onClick={handleSpotifyLinkSubmit} className="spotify-btn">
                Fetch Track Info
              </button>
            </div>

            {trackSearchResults.length > 0 && (
              <MusicTable
                selectedExercise={selectedExercise}
                selectedMusicDetails={trackSearchResults.map((track) => ({
                  musica: track,
                  ejercicio: selectedExercise.musica_ejercicio.ejercicio
                }))}
                accessToken={accessToken}
                handleSelectMusicExercise={handleSelectMusicExercise}
                playSong={playSong}
              />
            )}

          </div>
        )}


      </div>
    </div>
  );

};

export default MusicTabs;
