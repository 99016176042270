import { useEffect } from 'react';
import { useAuth } from '../../AuthContext';


const SessionDataFetcher = ({ sessionId, setSession, setExercises, setMusicDetails, setLoading }) => {
  // console.log("SessionDataFetcher props:", { sessionId, setSession, setExercises, setMusicDetails, setLoading });
  const { authFetch } = useAuth();
  useEffect(() => {
    setLoading(true);
    
    const fetchSession = async () => {
      try {
        const data = await authFetch(`/sesiones/api/sesion/${sessionId}/`);
        setSession(data);
        const fetchedExercises = data.ejercicios || [];

        // Process exercises to handle cases where musica_ejercicio is null
        const processedExercises = fetchedExercises.map(exercise => {
          if (exercise.musica_ejercicio === null && exercise.ejercicio_sin_musica) {
            // Create a new musica_ejercicio object with musica as null
            return {
              ...exercise,
              musica_ejercicio: {
                musica: null,
                ejercicio: exercise.ejercicio_sin_musica, // Reference to ejercicio_sin_musica
              }
            };
          }
          return exercise; // Return the original exercise if conditions are not met
        });

        setExercises(processedExercises);
        console.log(processedExercises)

        // Fetch music details for all exercises
        const musicPromises = processedExercises.map(exercise =>
          authFetch(`/sesiones/api/musicas_ejercicio/${exercise.musica_ejercicio?.ejercicio.id}/${sessionId}/`)
        );

        const musicDataArray = await Promise.all(musicPromises);

        console.log(musicDataArray)
        // Map music data to corresponding exercise IDs
        const musicMap = processedExercises.reduce((acc, exercise, index) => {
          acc[exercise.musica_ejercicio?.ejercicio.id] = musicDataArray[index];
          return acc;
        }, {});

        setMusicDetails(musicMap);
      } catch (error) {
        console.error('Error fetching session:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSession();
  }, [sessionId, setSession, setExercises, setMusicDetails, setLoading, authFetch]);

  return null; // This component does not render anything
};

export default SessionDataFetcher;
