import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import { AuthProvider, AuthContext } from './AuthContext';
import SpotifyAuth from './components/SpotifyAuth';
import ElencoBrowser from './components/ElencoBrowser';
import SessionExplorer from './components/sesion/SessionExplorer';
import SessionBuilder from './components/sesion/SessionBuilder';
import Login from './components/Login';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

const App = () => {
  const { token, logout } = useContext(AuthContext);
  const [spotifyToken, setSpotifyToken] = useState(null);
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_REDIRECT_URI;
  const scopes = process.env.REACT_APP_SCOPES;

  // Retrieve Spotify token from localStorage on initial load
  useEffect(() => {
    const storedToken = localStorage.getItem('spotifyAccessToken');
    if (storedToken) {
      setSpotifyToken(storedToken);
      validateSpotifyToken(storedToken);
    }
  }, []);

  // Function to validate the Spotify token
  const validateSpotifyToken = async (token) => {
    try {
      const response = await fetch('https://api.spotify.com/v1/me', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Invalid token');
      }

      const userData = await response.json();
      console.log('User Data:', userData);
    } catch (error) {
      console.error('Spotify token validation failed:', error);
      // Clear Spotify token only if validation fails
      setSpotifyToken(null);
      localStorage.removeItem('spotifyAccessToken');
    }
  };

  // Update spotifyToken both in state and localStorage
  const handleSetSpotifyToken = (spotifyToken) => {
    setSpotifyToken(spotifyToken);
    localStorage.setItem('spotifyAccessToken', spotifyToken);
  };

  // App logout handler (does not affect Spotify)
  const handleLogout = () => {
    logout(); // Call the AuthContext logout method
  };

  return (
    <div className="App">
      <h1>Crear Sesión</h1>
      <nav>
        <ul style={{ display: 'flex', listStyle: 'none', padding: 0 }}>
          <li style={{ marginRight: '20px' }}>
            <Link to="/" title="Session Explorer" className='nav-item'>
              <i className="fas fa-folder-open"></i>
            </Link>
          </li>
          <li style={{ marginRight: '20px' }}>
            <Link to="/elenco" title="Elenco Browser" className='nav-item'>
              <i className="fas fa-music"></i>
            </Link>
          </li>
          <li style={{ marginRight: '20px' }}>
            <button
              className='nav-item'
              onClick={handleLogout}
              title="Logout"
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            >
              <i className="fas fa-sign-out-alt"></i>
            </button>
          </li>
        </ul>
      </nav>
      <>
        {!spotifyToken ? (
          <div>
            <p>You need to log in to Spotify:</p>
            <SpotifyAuth setSpotifyToken={handleSetSpotifyToken} />
          </div>
        ) : token ? (
          <Routes>
            <Route path="/" element={<Navigate to="/sessions" />} />
            <Route path="/login" element={<Navigate to="/sessions" />} />
            <Route path="/elenco" element={<ElencoBrowser spotifyToken={spotifyToken} />} />
            <Route path="/sessions" element={<SessionExplorer />} />
            <Route
              path="/sesion/:sessionId"
              element={<SessionBuilder spotifyToken={spotifyToken} setSpotifyToken={handleSetSpotifyToken} />}
            />
          </Routes>
        ) : (
          <Routes>
            <Route path="*" element={<Navigate to="/login" />} />
            <Route
              path="/login"
              element={<Login token={token} spotifyToken={spotifyToken} setSpotifyToken={handleSetSpotifyToken} />}
            />
          </Routes>
        )}
      </>
    </div>
  );
};

// Wrap your app in Router for routing to work
const WrappedApp = () => (
  <Router>
    <AuthProvider>
      <App />
    </AuthProvider>
  </Router>
);

export default WrappedApp;

