import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const SpotifyAuth = ({ setSpotifyToken }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const hash = window.location.hash;
    console.log('Current URL hash:', hash); // Log the current URL hash

    if (hash) {
      const token = new URLSearchParams(hash.substring(1)).get('access_token');
      console.log('Retrieved Spotify token:', token); // Log the retrieved token

      if (token) {
        setSpotifyToken(token);
        localStorage.setItem('spotifyAccessToken', token);

        // Retrieve and clear the stored route
        const redirectTo = localStorage.getItem('redirectRoute') || '/sessions';
        localStorage.removeItem('redirectRoute');
        navigate(redirectTo); // Redirect to the stored route
      }
    }
  }, [setSpotifyToken, navigate]);

  const handleLogin = () => {
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_REDIRECT_URI;
    const scopes = process.env.REACT_APP_SCOPES;

    // Store the current route before login
    localStorage.setItem('redirectRoute', location.pathname);

    const authUrl = `https://accounts.spotify.com/authorize?client_id=${clientId}&response_type=token&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scopes)}`;
    window.location.href = authUrl;
  };

  return (
    <button onClick={handleLogin}>Log in with Spotify!</button>
  );
};

export default SpotifyAuth;
