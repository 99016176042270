import React from 'react';
import './ConfirmationModal.css';  // Create a CSS file for styling if necessary

const ConfirmationModal = ({ isOpen, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div className="confirmation-modal">
      <div className="modal-overlay" onClick={onCancel}></div>
      <div className="modal-content">
        <h3>Are you sure you want to duplicate this session?</h3>
        <div className="modal-actions">
          <button onClick={onConfirm}>Yes, Duplicate</button>
          <button onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;