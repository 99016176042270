import React, { useState, useEffect } from 'react';
import './ExerciseSelectionModal.css';
import { useAuth } from '../../AuthContext';

const ExerciseSelectionModal = ({ isOpen, onClose, availableExercises, onSelectExercise, sessionExercises }) => {
  const { authFetch } = useAuth();
  const [activeTab, setActiveTab] = useState('full_search');
  const [suggestedExercises, setSuggestedExercises] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedLineavivencia, setSelectedLineavivencia] = useState('');
  const [lineaVivencias, setLineaVivencias] = useState([]);
  const [checkedExercises, setCheckedExercises] = useState([]);


  // Fetch suggested exercises
  useEffect(() => {
    const fetchSuggestedExercises = async () => {
      try {
        const data = await authFetch('/sesiones/api/suggested_exercises/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: { existing_exercises_list: sessionExercises },
        });
        console.log(data)
        setSuggestedExercises(data.suggested_exercises || []);
      } catch (error) {
        console.error('Error fetching suggested exercises:', error);
      }
    };

    if (isOpen && activeTab === 'suggested') {
      fetchSuggestedExercises();
    }
  }, [isOpen, activeTab, authFetch, sessionExercises]);

  useEffect(() => {
    // Fetch LineaVivencia options from the API
    const fetchLineaVivencias = async () => {
      try {
        const data = await authFetch('/sesiones/api/linea_vivencias/');
        const vivenciasWithGrado = data.map(option => ({
          ...option,
          grado_asociacion: 0,
        }));
        setLineaVivencias(vivenciasWithGrado);
      } catch (error) {
        console.error('Error fetching LineaVivencias:', error);
      }
    };

    if (isOpen) {
      fetchLineaVivencias();
    }
  }, [isOpen, authFetch]);

  if (!isOpen) return null;

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle lineavivencia selection change
  const handleLineavivenciaChange = (event) => {
    setSelectedLineavivencia(event.target.value);
  };



  // Handlers
  const handleCheckboxChange = (exerciseId) => {
    setCheckedExercises(prev =>
      prev.includes(exerciseId)
        ? prev.filter(id => id !== exerciseId)
        : [...prev, exerciseId]
    );
  };

  const handleAddCheckedExercises = () => {
    checkedExercises.forEach(id => {
      const exercise = suggestedExercises.find(e => e.id === id);
      if (exercise) onSelectExercise(exercise);
    });
    setCheckedExercises([]);
  };

  // Filter exercises based on search query and selected lineavivencia
  const filteredExercises = availableExercises.filter(exercise =>
    exercise.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
    (selectedLineavivencia === '' ||
      (exercise.linea_vivencia &&
        exercise.linea_vivencia.toString() === selectedLineavivencia))
  );


  return (
    <div className="modal">
      <div className="modal-content wide-modal">
        <h2>Select an Exercise</h2>

        {/* Tabs */}
        <div className="tabs">
          <button
            className={activeTab === 'full_search' ? 'active' : ''}
            onClick={() => setActiveTab('full_search')}
          >
            Original Content
          </button>
          <button
            className={activeTab === 'suggested' ? 'active' : ''}
            onClick={() => setActiveTab('suggested')}
          >
            Suggested Exercises
          </button>
        </div>

        {/* Tab Content */}
        {activeTab === 'full_search' && (
          <>
            <input
              type="text"
              placeholder="Search exercises..."
              value={searchQuery}
              onChange={handleSearchChange}
              style={{ marginBottom: '10px', padding: '5px', width: '100%' }} // Optional styles
            />

            {/* Lineavivencia Filter */}
            <div style={{ marginBottom: '10px' }}>
              <label htmlFor="lineavivencia-select">Filter by Lineavivencia: </label>
              <select
                id="lineavivencia-select"
                value={selectedLineavivencia}
                onChange={handleLineavivenciaChange}
                style={{ marginLeft: '5px' }}
              >
                <option value="">Todas</option>
                {lineaVivencias.map((vivencia) => (
                  <option key={vivencia.id} value={vivencia.id}>
                    {vivencia.name} {/* Assuming 'name' is the field containing the display name */}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ maxHeight: '500px', overflowY: 'auto' }}> {/* Add vertical scroll */}
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th>Ejercicio</th>
                    <th>Última Vez</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredExercises.length > 0 ? (
                    filteredExercises.map((exercise, index) => (
                      <tr
                        key={index}
                        onClick={() => onSelectExercise(exercise)}
                        style={{
                          cursor: 'pointer',
                          backgroundColor: '#f9f9f9',
                          borderBottom: '1px solid #ccc',
                        }}
                      >
                        <td>{exercise.name}</td>
                        <td>{exercise.ult_fecha_ej}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">No exercises found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}

        {activeTab === 'suggested' && (
          <>
            {/* Suggested Exercises Table */}
            <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>Ejercicio</th>
                    <th>Última Vez</th> {/* New column for last exercise date */}
                  </tr>
                </thead>
                <tbody>
                  {suggestedExercises.length > 0 ? (
                    suggestedExercises
                      .filter((exercise) => !sessionExercises.some((sessionExercise) => sessionExercise.musica_ejercicio.ejercicio.id === exercise.id)) // Filter out session exercises
                      .map((exercise) => (
                        <tr
                          key={exercise.id}
                          style={{
                            cursor: 'pointer',
                            backgroundColor: '#f9f9f9',
                            borderBottom: '1px solid #ccc',
                          }}
                        >
                          <td>
                            <input
                              type="checkbox"
                              checked={checkedExercises.includes(exercise.id)}
                              onChange={() => handleCheckboxChange(exercise.id)}
                            />
                          </td>
                          <td>{exercise.name}</td>
                          <td>{exercise.ult_fecha_ej || 'N/A'}</td> {/* Display the last exercise date */}
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan="3">No suggested exercises found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <button onClick={handleAddCheckedExercises} style={{ marginTop: '10px' }}>
              Add Selected Exercises
            </button>
          </>
        )}


        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ExerciseSelectionModal;
